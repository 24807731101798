import { defineStore } from 'pinia';
import type { Contacts, UserInfo } from '@/apis/client/message/type';

const useClientContactsStore = defineStore('contacts', {
  state: () => {
    return {
      contact: void 0,
      msgLog: [], //聊天记录
      isQuitGroup: false // 判断是否退出群聊
    };
  },

  actions: {
    /**
     * 设置临时联系人
     * 通讯录点击记录，供消息页面使用，消息页面使用需清除此状态
     * @param contacts
     */
    setContacts(contacts: any) {
      this.contact = contacts;
    }
  }
});

export default useClientContactsStore;
